import {
    LOGIN_EXITO,
    ALMACENAR_PROFESION,
    ALMACENAR_RESIDENCIA,
    CERRAR_SESION,
    SETEAR_ID_PACIENTE_EXITO,
    SOLICITO_INFORMACION_PERFIL_PROFESIONAL_EXITO,
    SOLICITO_TOKEN_PERFIL_PROFESIONAL_EXITO,
    SOLICITO_SUSCRIPCION_PROFESIONAL_EXITO,
    CARGAR_DATOS_PACIENTE_NO_REGISTRADO_EXITO,
    SOLICITO_CANTIDAD_SUSCRIPCION_EXITO,
    ESTADO_PACIENTE_INITIAL,
    SUSPENDO_SUSCRIPTOR_EXITO,
    ACTIVO_SUSCRIPTOR_EXITO,
    CREAR_SUSCRIPTOR_EXITO
} from '../types';

const initialState = {
    id_usuario: '',
    nombre: '',
    rol: '',
    cantidad_suscripcion: '',
    id_paciente:'',
    nombrePaciente: '',
    apellidoPaciente: '',
    pais: '',
    provincia: '',
    departamento: '',
    localidad: '',
    perfil:[],
    suscripcion:[],
    modifico_email: '',
    nombreTutor: '',
    profesion: '',
    otroProfesional: '',
    ayuda_lectura: '',
    ayuda_letra: ''
}

export default function usuariosReducer(state = initialState, action) {
    switch(action.type) {
        case LOGIN_EXITO:
            return {
                ...state,
                id_usuario: action.payload.payloadToken.id_usuario,
                nombre: action.payload.payloadToken.nombre,
                id_paciente: action.payload.payloadToken.id_paciente,
                nombrePaciente: action.payload.payloadToken.nombrePaciente ? action.payload.payloadToken.nombrePaciente : '',
                rol: action.payload.payloadToken.rol,
                ayuda_lectura: action.payload.info_header?.ayuda_lectura,
                ayuda_letra: action.payload.info_header?.ayuda_letra
            }
        case ALMACENAR_PROFESION:
            return {
                ...state,
                profesion: action.payload.profession,
                otroProfesional: action.payload.otroProfesional
            }
        case ALMACENAR_RESIDENCIA:
            return {
                ...state,
                pais: action.payload[0],
                provincia: action.payload[1],
                departamento: action.payload[2],
                localidad: action.payload[3]
            }
        case CERRAR_SESION:
            return {
                id_usuario: '',
                nombre: '',
                rol: '',
                cantidad_suscripcion: '',
                id_paciente:'',
                nombrePaciente: '',
                apellidoPaciente: '',
                pais: '',
                provincia: '',
                departamento: '',
                localidad: '',
                perfil:[],
                suscripcion:[],
                modifico_email: '',
                nombreTutor: '',
                profesion: '',
                otroProfesional: '',
                ayuda_lectura: '',
                ayuda_letra: ''
            }
        case SETEAR_ID_PACIENTE_EXITO:
            return {
                ...state,
                id_paciente: action.payload.id,
                nombrePaciente: action.payload.nombre,
                apellidoPaciente: action.payload.apellido
            }
        case SOLICITO_INFORMACION_PERFIL_PROFESIONAL_EXITO:
        case CARGAR_DATOS_PACIENTE_NO_REGISTRADO_EXITO:
            return {
                ...state,
                perfil: action.payload
            }
        case SOLICITO_TOKEN_PERFIL_PROFESIONAL_EXITO:
            return {
                ...state,
                modifico_email: action.payload
            }
        case SOLICITO_SUSCRIPCION_PROFESIONAL_EXITO:
        case SUSPENDO_SUSCRIPTOR_EXITO:
        case ACTIVO_SUSCRIPTOR_EXITO:
        case CREAR_SUSCRIPTOR_EXITO:
            return {
                ...state,
                suscripcion: action.payload
            }
        case SOLICITO_CANTIDAD_SUSCRIPCION_EXITO:
            return {
                ...state,
                cantidad_suscripcion: action.payload
            }
        case ESTADO_PACIENTE_INITIAL:
            return {
                ...state,
                id_paciente: '',
                nombrePaciente: '',
                apellidoPaciente: ''
            }
        default:
            return state;
    }
}