import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";

//css
import {FlexColumn, CuerpoCompleto, FlexBox} from '../Css/StyledHeader.js'
import {Titulo, Fondo, Division, DivisionVertical, Input1, Label1, Texto, BotonNaranjaButton, Contenedor} from '../Css/StyledPatient.js'

// Actions de Redux
import { crearUsuarioPacienteAction } from '../../actions/crearPacienteActions';
import { setearAlerta, ocultarAlertaAction } from '../../actions/alertaActions';
import { cargarDatosPacienteNoRegistradoAction } from '../../actions/invitarPacienteActions.js';
import { FixedAlert, Img, UlAlert } from '../Css/StyledForm.js';
import Alertas from '../Metodos/Compartidos/Alertas.js';

const InvitacionPaciente = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {token} = useParams();

    const {email, nombre, apellido, fecha_nacimiento, nombre_1, apellido_1, email_1, nombre_2, apellido_2, email_2, tutorUnoExiste, tutorDosExiste} = useSelector((state) => state.usuario.perfil);
    const alerta = useSelector ( (state) => state.alertas.alerta);

    const [disable, setDisable] = useState(false);
    const [incompletoNombrePaciente, setIncompletoNombrePaciente] = useState(false);
    const [incompletoApellidoPaciente, setIncompletoApellidoPaciente] = useState(false);
    const [incompletoFechaNacimientoPaciente, setIncompletoFechaNacimientoPaciente] = useState(false);
    const [incompletoNombreTutor1, setIncompletoNombreTutor1] = useState(false);
    const [incompletoApellidoTutor1, setIncompletoApellidoTutor1] = useState(false);
    const [incompletoEmailTutor1, setIncompletoEmailTutor1] = useState(false);
    const [incompletoEmailConfirmacionTutor1, setIncompletoEmailConfirmacionTutor1] = useState(false);
    const [incompletoNombreTutor2, setIncompletoNombreTutor2] = useState(false);
    const [incompletoApellidoTutor2, setIncompletoApellidoTutor2] = useState(false);
    const [incompletoEmailTutor2, setIncompletoEmailTutor2] = useState(false);
    const [incompletoEmailConfirmacionTutor2, setIncompletoEmailConfirmacionTutor2] = useState(false);

    const [nombrePaciente, setNombrePaciente] = useState('');
    const [apellidoPaciente, setApellidoPaciente] = useState('');
    const [fechaNacimientoPaciente, setFechaNacimientoPaciente] = useState('');
    const [nombreTutor1, setNombreTutor1] = useState('');
    const [apellidoTutor1, setApellidoTutor1] = useState('');
    const [emailTutor1, setEmailTutor1] = useState('');
    const [emailConfirmacionTutor1, setEmailConfirmacionTutor1] = useState('');
    const [nombreTutor2, setNombreTutor2] = useState('');
    const [apellidoTutor2, setApellidoTutor2] = useState('');
    const [emailTutor2, setEmailTutor2] = useState('');
    const [emailConfirmacionTutor2, setEmailConfirmacionTutor2] = useState('');
    const [password, setPassword] = useState('');
    const [verificarPassword, setVerificarPassword] = useState('');
    const [visibilidadPassword, setVisibilidadPassword] = useState(false);
    const [focused, setFocused] = useState(false);

    const submitPacienteTemporal = (e) => {
        e.preventDefault();
        if(nombrePaciente.trim() === '' || 
        apellidoPaciente.trim() === '' || 
        fechaNacimientoPaciente.trim() === '' || 
        nombreTutor1.trim() === '' || 
        apellidoTutor1.trim() === '' ||
        emailTutor1 === '') {
            const alerta = {
                msg: 'Todos los campos son obligatorios'
            }
            dispatch( setearAlerta (alerta) );
            if(nombrePaciente.trim() === ''){setIncompletoNombrePaciente(true)} else {setIncompletoNombrePaciente(false)};
            if(apellidoPaciente.trim() === ''){setIncompletoApellidoPaciente(true)} else {setIncompletoApellidoPaciente(false)};
            if(fechaNacimientoPaciente.trim() === ''){setIncompletoFechaNacimientoPaciente(true)} else {setIncompletoFechaNacimientoPaciente(false)};
            if(nombreTutor1.trim() === ''){setIncompletoNombreTutor1(true)} else {setIncompletoNombreTutor1(false)};
            if(apellidoTutor1.trim() === ''){setIncompletoApellidoTutor1(true)} else {setIncompletoApellidoTutor1(false)};
            if(emailTutor1 === ''){setIncompletoEmailTutor1(true)} else {setIncompletoEmailTutor1(false)};
            if(emailConfirmacionTutor1 === ''){setIncompletoEmailConfirmacionTutor1(true)} else {setIncompletoEmailConfirmacionTutor1(false)};
            return;
        }
        if(nombreTutor2.trim() !== '' || 
        apellidoTutor2.trim() !== ''){
            if(nombreTutor2.trim() === '' || 
            apellidoTutor2.trim() === '' || 
            emailTutor2 === '' || 
            emailConfirmacionTutor2 === '' ) {
                const alerta = {
                    msg: 'Todos los campos son obligatorios'
                }
                dispatch( setearAlerta (alerta) );
                if(nombreTutor2.trim() === ''){setIncompletoNombreTutor2(true)} else {setIncompletoNombreTutor2(false)};
                if(apellidoTutor2.trim() === ''){setIncompletoApellidoTutor2(true)} else {setIncompletoApellidoTutor2(false)};
                if(emailTutor2 === ''){setIncompletoEmailTutor2(true)} else {setIncompletoEmailTutor2(false)};
                if(emailConfirmacionTutor2 === ''){setIncompletoEmailConfirmacionTutor2(true)} else {setIncompletoEmailConfirmacionTutor2(false)};
                return;
            }
        }
        if(password !== verificarPassword) {
            const alerta = {
                msg: 'Las contraseñas deben coincidir'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        if(password.length < 8) {
            const alerta = {
                msg: 'La contraseña debe contener al menos 8 caracteres'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        if(password.length > 15) {
            const alerta = {
                msg: 'La contraseña debe contener como mucho 15 caracteres'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        const minuscula = new RegExp("(?=.*[a-z])");
        if(!minuscula.test(password)) {
            const alerta = {
                msg: 'La contraseña debe contener al menos una minúscula'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        const mayuscula = new RegExp("(?=.*[A-Z])");
        if(!mayuscula.test(password)) {
            const alerta = {
                msg: 'La contraseña debe contener al menos una mayúscula'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        const numero = new RegExp("(?=.*[0-9])");
        if(!numero.test(password)) {
            const alerta = {
                msg: 'La contraseña debe contener al menos un número'
            }
            dispatch ( setearAlerta(alerta) );
            return;
        }
        dispatch( ocultarAlertaAction());

        const informacion = {
            payload: {
                'email': email,
                'nombre': nombrePaciente,
                'apellido': apellidoPaciente,
                'fecha_nacimiento': fechaNacimientoPaciente,
                'nombre_1': nombreTutor1,
                'apellido_1': apellidoTutor1,
                'email_1': emailTutor1,
                'nombre_2': nombreTutor2,
                'apellido_2': apellidoTutor2,
                'email_2': emailTutor2,
                'password': password,
                'token': token
            }
        }
        dispatch( crearUsuarioPacienteAction(informacion) );
        setDisable(true);
    }

    const mostrarPassword = (e) => {
        e.preventDefault();
        if(visibilidadPassword) {
            setVisibilidadPassword(false);
        } else {
            setVisibilidadPassword(true);
        }
        
    }

    useEffect(() => {
        const informacion = {
            payload: {token}
        }
        dispatch (cargarDatosPacienteNoRegistradoAction(informacion));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(nombre){
            setDisable(false);
            setNombrePaciente(nombre);
            setApellidoPaciente(apellido);
            setFechaNacimientoPaciente(fecha_nacimiento)
            setNombreTutor1(nombre_1);
            setApellidoTutor1(apellido_1);
            setEmailTutor1(email_1);
            setNombreTutor2(nombre_2);
            setApellidoTutor2(apellido_2);
            setEmailTutor2(email_2);
        } else {
            setDisable(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nombre])    

    return ( 
        <CuerpoCompleto height="170vh" width="100%">
            {alerta ? <Alertas /> : null}
            <div>
                <form
                    onSubmit={(e) => submitPacienteTemporal(e)}
                >
                    <Titulo>Confirmar datos Paciente</Titulo>
                    <FlexColumn align="center">
                        <Fondo width="90vw" height="45vh" alto="60vh">
                            <FlexBox >
                                <Label1 width="40vw" >
                                    <div htmlFor="nombre" >Nombre:</div>
                                    <Input1 width="90%"
                                        disabled={disable}
                                        type="text"
                                        id="nombrePaciente"
                                        name="nombrePaciente"
                                        value={nombrePaciente}
                                        onChange={e => setNombrePaciente(e.target.value)}
                                    />
                                    {!incompletoNombrePaciente ? null : <div>X</div>}
                                </Label1>
                                <DivisionVertical margin="0"/>
                                <Label1 width="40vw">
                                    <div htmlFor="apellidoPaciente" >Apellido:</div>
                                    <Input1 width="90%"
                                        disabled={disable}
                                        type="text"
                                        id="apellidoPaciente"
                                        name="apellidoPaciente"
                                        value={apellidoPaciente}
                                        onChange={e => setApellidoPaciente(e.target.value)}
                                    />
                                    {!incompletoApellidoPaciente ? null : <div>X</div>}
                                </Label1>
                                <Label1 width="40vw">
                                    <div htmlFor="fechaNacimientoPaciente" >Fecha nacimiento:</div>
                                    <Input1 width="90%"
                                        disabled={disable}
                                        type="date"
                                        id="fechaNacimientoPaciente"
                                        name="fechaNacimientoPaciente"
                                        value={fechaNacimientoPaciente}
                                        onChange={e => setFechaNacimientoPaciente(e.target.value)}
                                    />
                                    {!incompletoFechaNacimientoPaciente ? null : <div>X</div>}
                                </Label1>
                            </FlexBox>
                            <Division/>
                            <Label1 margin="20px 0px 0px 0px" padding="4px 0px 2px 7px">
                                {focused ?
                                    <FixedAlert transform="translate(105%, -0%)" >
                                        <UlAlert>
                                            <li>Contener entre 8-15 caracteres</li>
                                            <li>Al menos una minúscula y una mayúscula</li>
                                            <li>Al menos un número</li>
                                        </UlAlert>
                                    </FixedAlert>
                                :   null
                                }
                                <label htmlFor="password">Contraseña Paciente</label>
                                <Input1 width="25%"
                                    disabled={disable}
                                    type={visibilidadPassword ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    autoComplete='new-password'
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    onFocus={() => setFocused(true)}
                                    onBlur={() => setFocused(false)}
                                />
                                <Img onClick={e => mostrarPassword(e)} height="1.5rem" width="1.5rem" alt='' src={visibilidadPassword ? `/img/ojo_invisible.png` : `/img/ojo_visible.png`} />
                            </Label1>
                            <Label1 margin="7px 0px 20px 0px" padding="4px 0px 2px 7px">
                                <label htmlFor="verificacion">Confirma la contraseña</label>
                                <Input1 width="25%"
                                    disabled={disable}
                                    type={visibilidadPassword ? "text" : "password"}
                                    id="verificacion"
                                    name="verificacion"
                                    value={verificarPassword}
                                    onChange={e => setVerificarPassword(e.target.value)}
                                />
                                <Img onClick={e => mostrarPassword(e)} height="1.5rem" width="1.5rem" alt='' src={visibilidadPassword ? `/img/ojo_invisible.png` : `/img/ojo_visible.png`} />
                            </Label1>
                        </Fondo>
                    </FlexColumn>
                        
                    <CuerpoCompleto width="100vw">
                        <Titulo padding="20px 0px 0px 30px">Confirmar Datos padres/tutores</Titulo>
                        <FlexColumn align="center">  
                            <Fondo width="90vw">
                                <FlexBox justify="space-around">
                                    <Texto padding="0px 0px 10px 0px">Tutor1</Texto>
                                    <Texto padding="0px 0px 10px 0px">Tutor2</Texto>
                                </FlexBox>
                                <Division/>
                                <FlexColumn align="center">
                                    <FlexBox justify="space-between">
                                        <FlexColumn>
                                            <Label1>
                                                <div htmlFor="nombreTutor1">Nombre:</div>
                                                <Input1 width="90%"
                                                    disabled={disable}
                                                    type="text"
                                                    id="nombreTutor1"
                                                    name="nombreTutor1"
                                                    value={nombreTutor1}
                                                    onChange={e => setNombreTutor1(e.target.value)}
                                                />
                                                {!incompletoNombreTutor1 ? null : <div>X</div>}
                                            </Label1>
                                            <Division width="45vw"/>
                                            <Label1>
                                                <div htmlFor="apellidoTutor1">Apellido:</div>
                                                <Input1 width="90%"
                                                    disabled={disable}
                                                    type="text"
                                                    id="apellidoTutor1"
                                                    name="apellidoTutor1"
                                                    value={apellidoTutor1}
                                                    onChange={e => setApellidoTutor1(e.target.value)}
                                                />
                                                {!incompletoApellidoTutor1 ? null : <div>X</div>}
                                            </Label1>
                                            <Division width="45vw"/>
                                            <Label1>
                                                <div htmlFor="emailTutor1">Email:</div>
                                                <Input1 width="90%"
                                                    disabled={disable}
                                                    type="email"
                                                    id="emailTutor1"
                                                    name="emailTutor1"
                                                    value={emailTutor1}
                                                    onChange={e => setEmailTutor1(e.target.value)}
                                                />
                                                {tutorUnoExiste ? `El email ${tutorUnoExiste} ya está registrado, corregir en caso de que no sea suyo` : null}
                                                {!incompletoEmailTutor1 ? null : <div>X</div>}
                                                <div htmlFor="emailConfirmacionTutor1">EmailConfirmacion:</div>
                                                <Input1 width="90%"
                                                    disabled={disable}
                                                    type="emailConfirmacion"
                                                    id="emailConfirmacionTutor1"
                                                    name="emailConfirmacionTutor1"
                                                    value={emailConfirmacionTutor1}
                                                    onChange={e => setEmailConfirmacionTutor1(e.target.value)}
                                                />
                                                {!incompletoEmailConfirmacionTutor1 ? null : <div>X</div>}
                                            </Label1>
                                        </FlexColumn>
                                        <DivisionVertical margin="0px"/>
                                        <FlexColumn>
                                            <Label1>
                                                <div htmlFor="nombreTutor2">Nombre:</div>
                                                <Input1 width="90%"
                                                    disabled={disable}
                                                    type="text"
                                                    id="nombreTutor2"
                                                    name="nombreTutor2"
                                                    value={nombreTutor2}
                                                    onChange={e => setNombreTutor2(e.target.value)}
                                                />
                                                {!incompletoNombreTutor2 ? null : <div>X</div>}
                                            </Label1>
                                            <Division width="45vw"/>
                                            <Label1>
                                                <div htmlFor="apellidoTutor2">Apellido:</div>
                                                <Input1 width="90%"
                                                    disabled={disable}
                                                    type="text"
                                                    id="apellidoTutor2"
                                                    name="apellidoTutor2"
                                                    value={apellidoTutor2}
                                                    onChange={e => setApellidoTutor2(e.target.value)}
                                                />
                                                {!incompletoApellidoTutor2 ? null : <div>X</div>}
                                            </Label1>
                                            <Division width="45vw"/>
                                            <Label1>
                                                <div htmlFor="emailTutor2">Email:</div>
                                                <Input1 width="90%"
                                                    disabled={disable}
                                                    type="email"
                                                    id="emailTutor2"
                                                    name="emailTutor2"
                                                    value={emailTutor2}
                                                    onChange={e => setEmailTutor2(e.target.value)}
                                                />
                                                {tutorDosExiste ? `El email ${tutorDosExiste} ya está registrado, corregir en caso de que no sea suyo` : null}
                                                {!incompletoEmailTutor2 ? null : <div>X</div>}
                                                <div htmlFor="emailConfirmacionTutor2">EmailConfirmacion:</div>
                                                <Input1 width="90%"
                                                    disabled={disable}
                                                    type="emailConfirmacion"
                                                    id="emailConfirmacionTutor2"
                                                    name="emailConfirmacionTutor2"
                                                    value={emailConfirmacionTutor2}
                                                    onChange={e => setEmailConfirmacionTutor2(e.target.value)}
                                                />
                                                {!incompletoEmailConfirmacionTutor2 ? null : <div>X</div>}
                                            </Label1>
                                        </FlexColumn>
                                    </FlexBox>
                                    <FlexBox justify="flex-end">
                                    <BotonNaranjaButton margin="20px 30px 0px 0px"
                                        disabled={disable}
                                        type="submit"
                                    >
                                        CREAR
                                    </BotonNaranjaButton>
                                    </FlexBox>
                                </FlexColumn>
                            </Fondo>
                        </FlexColumn>
                    </CuerpoCompleto>
                </form>
            </div>
        </CuerpoCompleto>
    );
}
 
export default InvitacionPaciente;