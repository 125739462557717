import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useSelectDropwdown from '../../Hooks/useSelectDropwdown.js';
import { paisData, provinciaData, departamentoData, localidadData,nacionalidadData, nivelEstudiosData } from '../../Hooks/dropdowns.js';
import useConfigAuth from '../../Hooks/useConfigAuth.js';

//css
import {  ContenedorPrincipal, FlexColumn, ContendorPaciente, GridBox, ContenedorGrid, DivisionVertical, Division, InputTemp, LabelTemp, FormTemporal, Selecciono, TituloTemp, TituloDosTemp, FlexButton, BotonNaranja, FaltaDato } from '../Css/MetodosCSS/StyledFormPacTemp.js';

// Actions de Redux
import { crearPacienteAction } from '../../actions/crearPacienteActions';
import { setearAlerta, ocultarAlertaAction } from '../../actions/alertaActions';
import Alertas from '../Metodos/Compartidos/Alertas.js';

const FormularioPacienteTemporal = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [config] = useConfigAuth();

    const pacientes = useSelector((state) => state.informacion.lista_pacientes);
    const alerta = useSelector ( (state) => state.alertas.alerta);

    const [disable, setDisable] = useState(false);
    const [incompletoNombrePaciente, setIncompletoNombrePaciente] = useState(false);
    const [incompletoApellidoPaciente, setIncompletoApellidoPaciente] = useState(false);
    const [incompletoSexoPaciente, setIncompletoSexoPaciente] = useState(false);
    const [incompletoNacionalidad, setIncompletoNacionalidad] = useState(false);
    const [incompletoPais, setIncompletoPais] = useState(false);
    const [incompletoProvincia, setIncompletoProvincia] = useState(false);
    const [incompletoFechaNacimientoPaciente, setIncompletoFechaNacimientoPaciente] = useState(false);
    const [incompletoCurso, setIncompletoCurso] = useState(false);
    const [incompletoRepitioAnio, setIncompletoRepitioAnio] = useState(false);
    const [incompletoNombreTutor1, setIncompletoNombreTutor1] = useState(false);
    const [incompletoApellidoTutor1, setIncompletoApellidoTutor1] = useState(false);
    const [incompletoPaisTutor1, setIncompletoPaisTutor1] = useState(false);
    const [incompletoNivelEstudiosTutor1, setIncompletoNivelEstudiosTutor1] = useState(false);
    const [incompletoNombreTutor2, setIncompletoNombreTutor2] = useState(false);
    const [incompletoApellidoTutor2, setIncompletoApellidoTutor2] = useState(false);
    const [incompletoPaisTutor2, setIncompletoPaisTutor2] = useState(false);
    const [incompletoNivelEstudiosTutor2, setIncompletoNivelEstudiosTutor2] = useState(false);

    const emailPaciente = useRef();
    const nombrePaciente = useRef();
    const apellidoPaciente = useRef();
    const sexoPaciente = useRef();
    const direccion = useRef();
    const fechaNacimientoPaciente = useRef();
    const colegio = useRef();
    const curso = useRef();
    const repitioAnio = useRef();
    const documentoIdentidad = useRef();
    const coberturaMedica = useRef();
    const plan = useRef();
    const carnet = useRef();
    const medico = useRef();
    const medicacion = useRef();
    const diagnosticoPrevio = useRef();
    const motivoConsulta = useRef();
    const terapiaActual = useRef();
    const hermanos = useRef();
    const nombreTutor1 = useRef();
    const apellidoTutor1 = useRef();
    const fechaNacimientoTutor1 = useRef();
    const emailTutor1 = useRef();
    const celTutor1 = useRef();
    const direccionTutor1 = useRef();
    const profesionTutor1 = useRef();
    const ocupacionTutor1 = useRef();
    const estadoCivilTutor1 = useRef();
    const nombreTutor2 = useRef();
    const apellidoTutor2 = useRef();
    const fechaNacimientoTutor2 = useRef();
    const emailTutor2 = useRef();
    const celTutor2 = useRef();
    const direccionTutor2 = useRef();
    const profesionTutor2 = useRef();
    const ocupacionTutor2 = useRef();
    const estadoCivilTutor2 = useRef();
    const marketing = useRef();

    const [pais, SelectDropdownPais] = useSelectDropwdown('Pais', paisData);
    const [provincia, SelectDropdownProvincia] = useSelectDropwdown('Provincia', provinciaData,"",true,pais);
    const [departamento, SelectDropdownDepartamento] = useSelectDropwdown('Departamento', departamentoData,"",true,provincia);
    const [localidad, SelectDropdownLocalidad] = useSelectDropwdown('Localidad', localidadData,"",true,departamento);
    const [paisTutor1, SelectDropdownPaisTutor1] = useSelectDropwdown('Pais', paisData);
    const [provinciaTutor1, SelectDropdownProvinciaTutor1] = useSelectDropwdown('Provincia', provinciaData,"",true,paisTutor1);
    const [departamentoTutor1, SelectDropdownDepartamentoTutor1] = useSelectDropwdown('Departamento', departamentoData,"",true,provinciaTutor1);
    const [localidadTutor1, SelectDropdownLocalidadTutor1] = useSelectDropwdown('Localidad', localidadData,"",true,departamentoTutor1);
    const [paisTutor2, SelectDropdownPaisTutor2] = useSelectDropwdown('Pais', paisData, 'pais');
    const [provinciaTutor2, SelectDropdownProvinciaTutor2] = useSelectDropwdown('Provincia', provinciaData,"",true,paisTutor2);
    const [departamentoTutor2, SelectDropdownDepartamentoTutor2] = useSelectDropwdown('Departamento', departamentoData,"",true,provinciaTutor2);
    const [localidadTutor2, SelectDropdownLocalidadTutor2] = useSelectDropwdown('Localidad', localidadData,"",true,departamentoTutor2);
    const [nacionalidad, SelectDropdownNacionalidad] = useSelectDropwdown('Nacionalidad', nacionalidadData);
    const [nivelEstudiosTutor1, SelectDropdownNivelEstudiosTutor1] = useSelectDropwdown('Estudios', nivelEstudiosData);
    const [nivelEstudiosTutor2, SelectDropdownNivelEstudiosTutor2] = useSelectDropwdown('Estudios', nivelEstudiosData);

    const submitPacienteTemporal = (e) => {
        e.preventDefault();
        if(nombrePaciente.current.value.trim() === '' || 
        apellidoPaciente.current.value.trim() === '' || 
        sexoPaciente.current.value.trim() === '' || 
        nacionalidad === '' ||
        pais === '' || 
        provincia === '' ||
        fechaNacimientoPaciente.current.value === '' ||
        curso.current.value.trim() === '' || 
        repitioAnio.current.value.trim() === '' ||
        nombreTutor1.current.value.trim() === '' || 
        apellidoTutor1.current.value.trim() === '' ||
        paisTutor1 === '' || 
        nivelEstudiosTutor1 === '') {
            const alerta = {
                msg: 'Los campos con una X son obligatorios'
            }
            dispatch( setearAlerta (alerta) );
            if(nombrePaciente.current.value.trim() === ''){setIncompletoNombrePaciente(true)} else {setIncompletoNombrePaciente(false)};
            if(apellidoPaciente.current.value.trim() === ''){setIncompletoApellidoPaciente(true)} else {setIncompletoApellidoPaciente(false)};
            if(sexoPaciente.current.value.trim() === ''){setIncompletoSexoPaciente(true)} else {setIncompletoSexoPaciente(false)};
            if(nacionalidad === ''){setIncompletoNacionalidad(true)} else {setIncompletoNacionalidad(false)};
            if(pais === ''){setIncompletoPais(true)} else {setIncompletoPais(false)};
            if(provincia === ''){setIncompletoProvincia(true)} else {setIncompletoProvincia(false)};
            if(fechaNacimientoPaciente.current.value.trim() === ''){setIncompletoFechaNacimientoPaciente(true)} else {setIncompletoFechaNacimientoPaciente(false)};
            if(curso.current.value.trim() === ''){setIncompletoCurso(true)} else {setIncompletoCurso(false)};
            if(repitioAnio.current.value.trim() === ''){setIncompletoRepitioAnio(true)} else {setIncompletoRepitioAnio(false)};
            if(nombreTutor1.current.value.trim() === ''){setIncompletoNombreTutor1(true)} else {setIncompletoNombreTutor1(false)};
            if(apellidoTutor1.current.value.trim() === ''){setIncompletoApellidoTutor1(true)} else {setIncompletoApellidoTutor1(false)};
            if(paisTutor1 === ''){setIncompletoPaisTutor1(true)} else {setIncompletoPaisTutor1(false)};
            if(nivelEstudiosTutor1 === ''){setIncompletoNivelEstudiosTutor1(true)} else {setIncompletoNivelEstudiosTutor1(false)};
            return;
        }
        if(nombreTutor2.current.value.trim() !== '' || 
        apellidoTutor2.current.value.trim() !== ''){
            if(nombreTutor2.current.value.trim() === '' || 
            apellidoTutor2.current.value.trim() === '' || 
            paisTutor2 === '' || 
            nivelEstudiosTutor2 === '' ) {
                const alerta = {
                    msg: 'Los campos con una X son obligatorios'
                }
                dispatch( setearAlerta (alerta) );
                if(nombreTutor2.current.value.trim() === ''){setIncompletoNombreTutor2(true)} else {setIncompletoNombreTutor2(false)};
                if(apellidoTutor2.current.value.trim() === ''){setIncompletoApellidoTutor2(true)} else {setIncompletoApellidoTutor2(false)};
                if(paisTutor2 === ''){setIncompletoPaisTutor2(true)} else {setIncompletoPaisTutor2(false)};
                if(nivelEstudiosTutor2 === ''){setIncompletoNivelEstudiosTutor2(true)} else {setIncompletoNivelEstudiosTutor2(false)};
                return;
            }
        }
        dispatch( ocultarAlertaAction());

        const informacion = {
            config,
            payload: {
                'email': emailPaciente.current.value,
                'nombre': nombrePaciente.current.value,
                'apellido': apellidoPaciente.current.value,
                'sexo': sexoPaciente.current.value,
                'nacionalidad': nacionalidad,
                'pais': pais,
                'provincia': provincia,
                'ciudad': departamento,
                'localidad': localidad,
                'direccion': direccion.current.value,
                'fecha_nacimiento': fechaNacimientoPaciente.current.value,
                'colegio': colegio.current.value,
                'curso': curso.current.value,
                'repitio_anio': repitioAnio.current.value,
                'dni': documentoIdentidad.current.value,
                'cobertura_medica': coberturaMedica.current.value,
                'plan': plan.current.value,
                'numero_afiliado': carnet.current.value,
                'medico': medico.current.value,
                'medicacion': medicacion.current.value,
                'diagnostico_previo': diagnosticoPrevio.current.value,
                'motivo_consulta': motivoConsulta.current.value,
                'terapias_actuales': terapiaActual.current.value,
                'hermanos': hermanos.current.value,
                'nombre_1': nombreTutor1.current.value,
                'apellido_1': apellidoTutor1.current.value,
                'fecha_nacimiento_1': fechaNacimientoTutor1.current.value,
                'email_1': emailTutor1.current.value,
                'telefono_1': celTutor1.current.value,
                'pais_1': paisTutor1,
                'provincia_1': provinciaTutor1,
                'ciudad_1': departamentoTutor1,
                'localidad_1': localidadTutor1,
                'direccion_1': direccionTutor1.current.value,
                'nivel_estudios_1': nivelEstudiosTutor1,
                'profesion_1': profesionTutor1.current.value,
                'ocupacion_1': ocupacionTutor1.current.value,
                'estado_civil_1': estadoCivilTutor1.current.value,
                'nombre_2': nombreTutor2.current.value,
                'apellido_2': apellidoTutor2.current.value,
                'fecha_nacimiento_2': fechaNacimientoTutor2.current.value,
                'email_2': emailTutor2.current.value,
                'telefono_2': celTutor2.current.value,
                'pais_2': paisTutor2,
                'provincia_2': provinciaTutor2,
                'ciudad_2': departamentoTutor2,
                'localidad_2': localidadTutor2,
                'direccion_2': direccionTutor2.current.value,
                'nivel_estudios_2': nivelEstudiosTutor2,
                'profesion_2': profesionTutor2.current.value,
                'ocupacion_2': ocupacionTutor2.current.value,
                'estado_civil_2': estadoCivilTutor2.current.value,
                'conocieron': marketing.current.value
            }
        }
        dispatch( crearPacienteAction(informacion) );
        setDisable(true);
    }

    useEffect(() => {
        if(disable === false){
            return
        }
        navigate("/escritorio/pacientes");
        setDisable(false);
    }, [disable]);

    return ( 
        <ContenedorPrincipal>
            {alerta ? <Alertas /> : null}
            <FormTemporal
                onSubmit={submitPacienteTemporal}
                    >
                <FlexColumn align="center">
                    <ContendorPaciente>
                        <TituloTemp $color="#23AAAF">Datos del Paciente</TituloTemp>
                        <Division $border="1px solid black" />
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="20% 75% 5%">
                                <LabelTemp htmlFor="nombre">Nombre:</LabelTemp>
                                <InputTemp 
                                    disabled={disable}
                                    type="text"
                                    id="nombrePaciente"
                                    name="nombrePaciente"
                                    // placeholder="Nombre Paciente"
                                    ref={nombrePaciente}
                                />
                                {!incompletoNombrePaciente ? null : <FaltaDato>X</FaltaDato>}
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="20% 75% 5%">
                                <LabelTemp htmlFor="apellidoPaciente">Apellido:</LabelTemp>
                                <InputTemp 
                                    disabled={disable}
                                    type="text"
                                    id="apellidoPaciente"
                                    name="apellidoPaciente"
                                    // placeholder="Apellido Paciente"
                                    ref={apellidoPaciente}
                                />
                                {!incompletoApellidoPaciente ? null : <FaltaDato>X</FaltaDato>}
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="20% 75% 5%">
                                <LabelTemp htmlFor="sexoPaciente">Sexo:</LabelTemp>
                                <select ref={sexoPaciente}>
                                    <option></option>
                                    <option>Masculino</option>
                                    <option>Femenino</option>
                                </select>
                                {!incompletoSexoPaciente ? null : <FaltaDato>X</FaltaDato>}
                            </ContenedorGrid> 
                            <DivisionVertical />   
                            <ContenedorGrid $disposicion="35% 60% 5%">
                                <LabelTemp htmlFor="fechaNacimientoPaciente">Fecha Nacimiento:</LabelTemp>
                                <InputTemp 
                                    disabled={disable}
                                    type="date"
                                    id="fechaNacimientoPaciente"
                                    name="fechaNacimientoPaciente"
                                    // placeholder="Fecha Nacimiento Paciente"
                                    ref={fechaNacimientoPaciente}
                                />
                                {!incompletoFechaNacimientoPaciente ? null : <FaltaDato>X</FaltaDato>}
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="100%">
                            <ContenedorGrid $disposicion="15% 85%">
                                <LabelTemp htmlFor="emailPaciente">Email:</LabelTemp>
                                <InputTemp 
                                    disabled={disable}
                                    type="email"
                                    id="emailPaciente"
                                    name="emailPaciente"
                                    // placeholder="Email Paciente"
                                    ref={emailPaciente}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="18.4% 2% 18.4% 2% 18.4% 2% 18.4% 2% 18.4%">
                            <SelectDropdownNacionalidad disable={disable} incompleto={incompletoNacionalidad} />
                            <DivisionVertical />
                            <SelectDropdownPais disable={disable} incompleto={incompletoPais} />
                            <DivisionVertical />
                            <SelectDropdownProvincia disable={disable} incompleto={incompletoProvincia} />
                            <DivisionVertical />
                            <SelectDropdownDepartamento disable={disable} />
                            <DivisionVertical />
                            <SelectDropdownLocalidad disable={disable} />
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="100%">
                            <ContenedorGrid $disposicion="15% 85%">
                                <LabelTemp htmlFor="direccion">Direccion:</LabelTemp>
                                <InputTemp 
                                    disabled={disable}
                                    type="text"
                                    id="direccion"
                                    name="direccion"
                                    // placeholder="direccion"
                                    ref={direccion}
                                />
                            </ContenedorGrid>
                            
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="40% 60%">
                                <LabelTemp htmlFor="documentoIdentidad">Documento de Identidad:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="documentoIdentidad"
                                    name="documentoIdentidad"
                                    // placeholder="Documento de Identidad"
                                    ref={documentoIdentidad}
                                />
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="25% 75%">
                                <LabelTemp htmlFor="colegio">Colegio:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="colegio"
                                    name="colegio"
                                    // placeholder="Colegio"
                                    ref={colegio}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="25% 70% 5%">
                                <LabelTemp htmlFor="curso">Curso:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="curso"
                                    name="curso"
                                    // placeholder="Curso"
                                    ref={curso}
                                />
                                {!incompletoCurso ? null : <FaltaDato>X</FaltaDato>}
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="35% 60% 5%">
                                <LabelTemp htmlFor="repitioAnio">Repitió Año?:</LabelTemp>
                                <Selecciono ref={repitioAnio}>
                                    <option></option>
                                    <option>Si</option>
                                    <option>No</option>
                                </Selecciono>
                                {!incompletoRepitioAnio ? null : <FaltaDato>X</FaltaDato>}
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="30% 70%">
                                <LabelTemp htmlFor="coberturaMedica">Obra Social:</LabelTemp>
                                <InputTemp 
                                    disabled={disable}
                                    type="text"
                                    id="coberturaMedica"
                                    name="coberturaMedica"
                                    // placeholder="Obra Social"
                                    ref={coberturaMedica}
                            />
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="30% 70%">
                                <LabelTemp htmlFor="plan">Plan:</LabelTemp>
                                <InputTemp 
                                    disabled={disable}
                                    type="text"
                                    id="plan"
                                    name="plan"
                                    // placeholder="Plan"
                                    ref={plan}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="100%">
                            <ContenedorGrid $disposicion="20% 80%">
                            <LabelTemp htmlFor="carnet">Carnet:</LabelTemp>
                                <InputTemp 
                                    disabled={disable}
                                    type="text"
                                    id="carnet"
                                    name="carnet"
                                    // placeholder="Carnet"
                                    ref={carnet}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="45% 2% 53%">
                            <ContenedorGrid $disposicion="25% 75%">
                                <LabelTemp htmlFor="medico">Médico:</LabelTemp>
                                <InputTemp 
                                    disabled={disable}
                                    type="text"
                                    id="medico"
                                    name="medico"
                                    // placeholder="Médico"
                                    ref={medico}
                                />
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="27% 73%">
                                <LabelTemp htmlFor="medicacion">Medicación:</LabelTemp>
                                <InputTemp 
                                    disabled={disable}
                                    type="text"
                                    id="medicacion"
                                    name="medicacion"
                                    // placeholder="Medicación"
                                    ref={medicacion}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="100%">
                            <ContenedorGrid $disposicion="25% 75%">
                                <LabelTemp htmlFor="diagnosticoPrevio">Diagnóstico Previo:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="diagnosticoPrevio"
                                    name="diagnosticoPrevio"
                                    // placeholder="Diagnóstico Previo"
                                    ref={diagnosticoPrevio}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="100%">
                            <ContenedorGrid $disposicion="22% 78%">
                                <LabelTemp htmlFor="motivoConsulta">Motivo Consulta:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="motivoConsulta"
                                    name="motivoConsulta"
                                    // placeholder="Motivo Consulta"
                                    ref={motivoConsulta}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="100%">
                            <ContenedorGrid $disposicion="22% 78%">
                                <LabelTemp htmlFor="terapiaActual">Terapia actual:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="terapiaActual"
                                    name="terapiaActual"
                                    // placeholder="Motivo Consulta"
                                    ref={terapiaActual}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="100%">
                            <ContenedorGrid $disposicion="20% 80%">
                                <LabelTemp htmlFor="hermanos">Hermanos:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="hermanos"
                                    name="hermanos"
                                    // placeholder="Hermanos"
                                    ref={hermanos}
                                />
                            </ContenedorGrid>
                        </GridBox>
                    </ContendorPaciente>
                </FlexColumn>
                <FlexColumn align="center">
                    <ContendorPaciente>
                        <TituloTemp $margin="2rem 0 2rem 0" >Datos padres/tutores</TituloTemp>
                        <GridBox $disposicion="50% 50%" $textalign="center" >
                            <TituloDosTemp>Padre/Tutor 1</TituloDosTemp>
                            <TituloDosTemp>Madre/Tutor 2</TituloDosTemp>
                        </GridBox>
                        <Division $border="1px solid black" />
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="25% 70% 5%">
                                <LabelTemp htmlFor="nombreTutor1">Nombre 1:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="nombreTutor1"
                                    name="nombreTutor1"
                                    // placeholder="Nombre Tutor1"
                                    ref={nombreTutor1}
                                />
                                {!incompletoNombreTutor1 ? null : <FaltaDato>X</FaltaDato>}
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="25% 70% 5%">
                                <LabelTemp htmlFor="nombreTutor2">Nombre 2:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="nombreTutor2"
                                    name="nombreTutor2"
                                    // placeholder="Nombre Tutor2"
                                    ref={nombreTutor2}
                                />
                                {!incompletoNombreTutor2 ? null : <FaltaDato>X</FaltaDato>}
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="25% 70% 5%">
                                <LabelTemp htmlFor="apellidoTutor1">Apellido 1:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="apellidoTutor1"
                                    name="apellidoTutor1"
                                    // placeholder="Apellido Tutor1"
                                    ref={apellidoTutor1}
                                />
                                {!incompletoApellidoTutor1 ? null : <FaltaDato>X</FaltaDato>}
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="25% 70% 5%">
                                <LabelTemp htmlFor="apellidoTutor2">Apellido 2:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="apellidoTutor2"
                                    name="apellidoTutor2"
                                    // placeholder="Apellido Tutor2"
                                    ref={apellidoTutor2}
                                />
                                {!incompletoApellidoTutor2 ? null : <FaltaDato>X</FaltaDato>}
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="50% 50%">
                                <LabelTemp htmlFor="fechaNacimientoTutor1">Fecha Nacimiento 1:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="date"
                                    id="fechaNacimientoTutor1"
                                    name="fechaNacimientoTutor1"
                                    // placeholder="Fecha Nacimiento Tutor1"
                                    ref={fechaNacimientoTutor1}
                                />
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="50% 50%">
                                <LabelTemp htmlFor="fechaNacimientoTutor2">Fecha Nacimiento 2:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="date"
                                    id="fechaNacimientoTutor2"
                                    name="fechaNacimientoTutor2"
                                    // placeholder="Fecha Nacimiento Tutor2"
                                    ref={fechaNacimientoTutor2}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="40% 60%">
                                <LabelTemp htmlFor="celTutor1">Celular 1:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="tel"
                                    id="celTutor1"
                                    name="celTutor1"
                                    // placeholder="Celular Tutor1"
                                    ref={celTutor1}
                                />
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="40% 60%">
                                <LabelTemp htmlFor="celTutor2">Celular 2:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="tel"
                                    id="celTutor2"
                                    name="celTutor2"
                                    // placeholder="Celular Tutor2"
                                    ref={celTutor2}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="50% 50%">
                                <SelectDropdownPaisTutor1 disable={disable} incompleto={incompletoPaisTutor1} />
                                <SelectDropdownProvinciaTutor1 disable={disable} />
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="50% 50%">
                                <SelectDropdownPaisTutor2 disable={disable} incompleto={incompletoPaisTutor2} />
                                <SelectDropdownProvinciaTutor2 disable={disable} />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="50% 50%">
                                <SelectDropdownDepartamentoTutor1 disable={disable} />
                                <SelectDropdownLocalidadTutor1 disable={disable} />
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="50% 50%">
                                <SelectDropdownDepartamentoTutor2 disable={disable} />
                                <SelectDropdownLocalidadTutor2 disable={disable} />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="32% 68%">
                                <LabelTemp htmlFor="direccionTutor1">Dirección 1:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="direccionTutor1"
                                    name="direccionTutor1"
                                    // placeholder="Dirección Tutor1"
                                    ref={direccionTutor1}
                                />
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="32% 68%">
                                <LabelTemp htmlFor="direccionTutor2">Dirección 2:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="direccionTutor2"
                                    name="direccionTutor2"
                                    // placeholder="Dirección Tutor2"
                                    ref={direccionTutor2}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="28% 72%">
                                <LabelTemp htmlFor="emailTutor1">Email 1:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="email"
                                    id="emailTutor1"
                                    name="emailTutor1"
                                    // placeholder="Email Tutor1"
                                    ref={emailTutor1}
                                />
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="28% 72%">
                                <LabelTemp htmlFor="emailTutor2">Email 2:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="email"
                                    id="emailTutor2"
                                    name="emailTutor2"
                                    // placeholder="Email Tutor2"
                                    ref={emailTutor2}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="100%">
                                <SelectDropdownNivelEstudiosTutor1 disable={disable} incompleto={incompletoNivelEstudiosTutor1} />
                            </ContenedorGrid>
                            <DivisionVertical />
                                <SelectDropdownNivelEstudiosTutor2 disable={disable} incompleto={incompletoNivelEstudiosTutor2} />
                            <ContenedorGrid $disposicion="100%">
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="28% 72%">
                                <LabelTemp htmlFor="profesionTutor1">Profesión 1:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="profesionTutor1"
                                    name="profesionTutor1"
                                    // placeholder="Profesión Tutor1"
                                    ref={profesionTutor1}
                                />
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="28% 72%">
                                <LabelTemp htmlFor="profesionTutor2">Profesión 2:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="profesionTutor2"
                                    name="profesionTutor2"
                                    // placeholder="Profesión Tutor2"
                                    ref={profesionTutor2}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="28% 72%">
                                <LabelTemp htmlFor="ocupacionTutor1">Ocupación 1:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="ocupacionTutor1"
                                    name="ocupacionTutor1"
                                    // placeholder="Ocupación Tutor1"
                                    ref={ocupacionTutor1}
                                />
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="28% 72%">
                                <LabelTemp htmlFor="ocupacionTutor2">Ocupación 2:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="ocupacionTutor2"
                                    name="ocupacionTutor2"
                                    // placeholder="Ocupación Tutor2"
                                    ref={ocupacionTutor2}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="49% 2% 49%">
                            <ContenedorGrid $disposicion="32% 68%">
                                <LabelTemp htmlFor="estadoCivilTutor1">Estado Civil 1:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="estadoCivilTutor1"
                                    name="estadoCivilTutor1"
                                    // placeholder="Estado Civil Tutor1"
                                    ref={estadoCivilTutor1}
                                />
                            </ContenedorGrid>
                            <DivisionVertical />
                            <ContenedorGrid $disposicion="32% 68%">
                                <LabelTemp htmlFor="estadoCivilTutor2">Estado Civil 2:</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="estadoCivilTutor2"
                                    name="estadoCivilTutor2"
                                    // placeholder="Estado Civil Tutor2"
                                    ref={estadoCivilTutor2}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                        <GridBox $disposicion="100%">
                            <ContenedorGrid $disposicion="26% 74%">
                                <LabelTemp htmlFor="marketing">Como nos conocieron</LabelTemp>
                                <InputTemp
                                    disabled={disable}
                                    type="text"
                                    id="marketing"
                                    name="marketing"
                                    // placeholder="Como nos conocieron"
                                    ref={marketing}
                                />
                            </ContenedorGrid>
                        </GridBox>
                        <Division/>
                    </ContendorPaciente>
                </FlexColumn>
                <FlexButton justify="flex-end">
                    <BotonNaranja margin="1rem 1rem 1rem 0rem"
                        disabled={disable}
                        type="submit"
                    >
                        CREAR
                    </BotonNaranja>
                </FlexButton>
            </FormTemporal>
        </ContenedorPrincipal>
    );
}
 
export default FormularioPacienteTemporal;